<template>
    <v-dialog v-model="msgpara.showmsg">
        <v-card light id="cartRadius">
            <v-card-title class="">
                {{ msgpara.header }}
            </v-card-title>

            <v-card-text class="font-weight-light title">
                <p class="pa-0" :class="getMsgClass()">
                    {{ msgpara.message }}
                </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="msgNo" text color="error" v-if="msgpara.yesno">
                    No
                </v-btn>
                <v-btn @click="msgYes" text color="success" v-if="msgpara.yesno">
                    Yes
                </v-btn>
                    <v-btn @click="msgClose" text color="black" v-if="msgpara.closeonly">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
     props: ['showmsg','msgpara'],   
     methods: {
        msgClose(){
           this.$emit('msgClose') 
        }, 
        msgYes(){
           this.$emit('msgYes') 
        }, 
        msgNo(){
           this.$emit('msgNo') 
        },
        getMsgClass(){
            //msgpara.iserr ? vmsgerror : vmsg
            if(this.msgpara.iserr){
               return 'vmsgerror'
            }else{
               return 'vmsg' 
            }
        } 
     }
  }
</script>

<style>
   .vtbtitle {
      color: whitesmoke; 
   }
   .vmsg {
      color: black; 
      margin: auto; 
   }
   .vmsgerror {
      color: red;
   }
</style>
const DB_NAME = 'acezcodedb';
const DB_VERSION = 1;
let DB;

export default {

	getDb() {
        //console.log('getDB')
		return new Promise((resolve, reject) => {

			if(DB) { return resolve(DB); }
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);
			
			request.onerror = e => {
				console.log('Error opening db', e);
				reject('Error opening db');
			};
	
			request.onsuccess = e => {
                DB = e.target.result;
                //console.log('OPENING DB', DB);
				resolve(DB);
			};
			
			request.onupgradeneeded = e => {
				//console.log('onupgradeneeded');
				let db = e.target.result;
				db.createObjectStore(DB_NAME, { autoIncrement: false, keyPath:'acezcode' });
			};
		});
    },
    
	async getUData() {
		let db = await this.getDb();
        //db.then(() => {
            return new Promise(resolve => {

                let trans = db.transaction([DB_NAME],'readonly');
                trans.oncomplete = () => {
                    resolve(data);
                };
                
                let store = trans.objectStore(DB_NAME);
                let data = [];
                
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    //if (cursor) {
                    //    data.push(cursor.value)
                    //    cursor.continue();
                    //}
                    if (cursor) {
                        if(data.length == 0){
                           data.push(cursor.value)
                        }else{
                           data.splice(0, 1, cursor.value) 
                        }
                        cursor.continue();
                    }
                };

            });
        //})
	},

    //Performs Add or Update
	async saveUData(data) {
		let db = await this.getDb();
        //db.then(() => {
            return new Promise(resolve => {

                let trans = db.transaction([DB_NAME],'readwrite');
                trans.oncomplete = () => {
                    resolve();
                };

                let store = trans.objectStore(DB_NAME);
                store.put(data);
            });
        //})
    },

    //Performs Table Clear
	async clearUData() {
		let db = await this.getDb();
        //db.then(() => {
            return new Promise(resolve => {

                let trans = db.transaction([DB_NAME],'readwrite');
                trans.oncomplete = () => {
                    resolve();
                };

                let store = trans.objectStore(DB_NAME);
                store.clear();
            });
        //})
    },

}
<template>
    <div id="fillHeight" class="yellow lighten-5">
        <feedbackmsg :msgpara="msgpara" @msgYes="msgYes" @msgNo="msgNo" @msgClose="msgClose"/>
        <v-container>
            <v-row align="center" justify="center" v-if="showLogin">
                <v-col cols="12" sm="12">
                    <v-card flat color="yellow lighten-5">
                        <!-- <p class="title text-center">Customer Information</p> -->
                        <v-row align="center" justify="center">
                            <v-col cols="auto" class="mb-8">
                                <v-avatar size="170" color="red lighten-5">
                                    <v-img :src="require('../assets/loglogo.png')" contain height="130" width="130"></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>

                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="11" class="mt-2 mb-2">
                                <v-text-field
                                    label="Code"
                                    hide-details
                                    v-model="drivercode"
                                    solo
                                    rounded
                                    flat
                                    class="elevation-1"
                                    type="password"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="11" class="mt-2">
                                <v-btn color="red darken-4" dark block rounded @click="login">
                                    Login
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row align="center" justify="center" v-if="showCreate">
                <v-col cols="12" sm="12">
                    <v-card flat color="yellow lighten-5">
                        <!-- <p class="title text-center">Customer Information</p> -->
                        <v-row align="center" justify="center">
                            <v-col cols="auto" class="mb-8">
                                <v-avatar size="170" color="red lighten-5">
                                    <v-img :src="require('../assets/loglogo.png')" contain height="130" width="130"></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>

                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="11" class="mb-2">
                                <v-text-field
                                    label="Body Number"
                                    hide-details
                                    v-model="bodynum"
                                    solo
                                    rounded
                                    flat
                                    class="elevation-1"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="11" class="mt-2 mb-2">
                                <v-text-field
                                    label="Code"
                                    hide-details
                                    v-model="drivercode"
                                    solo
                                    rounded
                                    flat
                                    class="elevation-1"
                                    type="password"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="11" class="mt-2">
                                <v-btn color="red darken-4" dark block rounded  @click="confirmCreate">
                                    Create Code
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <p class="subtitle-2 font-weight-light" id="verfooter">Version 1.1</p>
    </div>
</template>

<script>
  import feedbackmsg from '@/components/FeedbackMsg'
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  import Localbase from 'localbase'

  let db = new Localbase('acezcodedb')  
  db.config.debug = false

  export default {
    components: { feedbackmsg },  
    data () {
        return {
            //data: 'Code',
            bodynum: '',
            drivercode: '',
            data: [],
            dialog: false,
            showLogin: false,
            showCreate: true,
            msgpara: { header: '', icon: null, message: '',
                       iserr: false, closeonly: false, yesno: false, yesnocancel: false, showmsg: false
                     },
        }
    },

    //watch: {
    //   showLogin(){
    //       console.log('This showlogin: '+this.showlogin) 
    //   }
    //},

    methods: {
        login () {
            var self = this
            db.collection('user').limit(1).get()
            .then(user => {
                if(user.length > 0){
                    //this.showLogin = true
                    if(user[0].drivercode.toUpperCase() === self.drivercode.toUpperCase() || 
                       user[0].drivercode === self.drivercode ){
                         self.$router.push('/mainmenu')
                         setTimeout(() => {
                            self.drivercode = '' 
                            self.showLogin = true
                            self.showCreate = false
                            self.$router.push({ path: '/'})
                         }, 900000);
                    }else{
                        self.dialog = true
                        self.drivercode = ''
                        self.showLoginErr()
                    }
                }else{
                    self.dialog = true
                    self.drivercode = ''
                    self.showLoginErr()
                }
            })
        },
        async createCode () {
            this.data = []
            await this.checkDriverCode() 
            if(this.data[0].bodynum === "K"){
                var oRecord = [
                    { bodynum: this.bodynum,  
                      drivercode: this.drivercode
                    }
                ]
                db.collection('user').doc(this.drivercode).set({
                    bodynum: this.bodynum,
                    drivercode: this.drivercode
                })

                try {//serverIP
                    const resp = await axios.post(API_URL+'/drivercodepost/',oRecord)
                    //const resp = await axios.post('http://'+this.serverIP+':8764/acezcodeget/',oRecord)
                    //this.data = resp.data 
                    console.log('2: '+resp.data)
                } catch (err) {
                    // Handle Error Here
                    alert(err);
                }

                this.drivercode = ''
                this.showLogin = true
                this.showCreate = false
            }else{
                this.msgpara.header = 'Info'
                this.msgpara.message = 'Record exist! Body:'+this.data[0].bodynum+' Code:'+this.data[0].drivercode
                this.msgpara.yesno = false
                this.msgpara.closeonly = true
                this.msgpara.showmsg = true
                this.msgpara.iserr = true
            }
        },
        async checkDriverCode(){
            var oRecord = [
                { bodynum: this.bodynum,  
                  drivercode: this.drivercode
                }
            ]
            try {
                const resp = await axios.post(API_URL+'/drivercodeget/',oRecord)
                this.data = resp.data
            } catch (err) {
                // Handle Error Here
                alert('1: '+err);
            }
        },
        confirmCreate(){
            if(this.drivercode && this.bodynum){
                this.msgpara.header = 'Confirm'
                this.msgpara.message = 'Proceed to create Driver Code?'
                this.msgpara.yesno = true
                this.msgpara.closeonly = false
                this.msgpara.showmsg = true
                this.msgpara.iserr = false
            }else{
                this.msgpara.header = 'Info'
                this.msgpara.message = 'Incomplete Entry'
                this.msgpara.yesno = false
                this.msgpara.closeonly = true
                this.msgpara.showmsg = true
                this.msgpara.iserr = true
            }
        },
        msgNo() {
            this.msgpara.header = ''
            this.msgpara.message = ''
            this.msgpara.yesno = false
            this.msgpara.closeonly = false
            this.msgpara.showmsg = false
        },
        msgYes() {
            this.msgpara.header = ''
            this.msgpara.message = ''
            this.msgpara.yesno = false
            this.msgpara.closeonly = false
            this.msgpara.showmsg = false
            this.createCode()
        },
        msgClose() {
            this.msgpara.header = ''
            this.msgpara.message = ''
            this.msgpara.yesno = false
            this.msgpara.closeonly = false
            this.msgpara.showmsg = false
        },
        showErr() {
            this.msgpara.header = 'Info'
            this.msgpara.message = 'Problem contacting Server'
            this.msgpara.yesno = false
            this.msgpara.closeonly = true
            this.msgpara.showmsg = true
            this.msgpara.iserr = true
        },
        showLoginErr() {
            this.msgpara.header = 'Info'
            this.msgpara.message = 'Invalid Code!'
            this.msgpara.yesno = false
            this.msgpara.closeonly = true
            this.msgpara.showmsg = true
            this.msgpara.iserr = true
        },
    },

    mounted(){
        var self = this
        db.collection('user').limit(1).get()
        .then(user => {
            if(user.length > 0){
                self.showLogin = true
                self.showCreate = false
            }
        })
        
    },
  }
</script>
<template>
  <div class="grey lighten-3" style="min-height: 100vh; height: 100%;">
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <MenuItem v-for="menuitem in menuItemLinks" :key="menuitem.id" v-bind="menuitem"/>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MenuItem from '@/components/MenuItem';
export default {
   name: 'MainMenu',
   data(){ 
     return {
        menuItemLinks: [
          {title: 'Search Code', icon: 'fas fa-search', to: '/search', id: '1'},
          {title: 'Update List', icon: 'fas fa-list', to: '/update', id: '2'},
        ]
     }
   },
   components: {
      MenuItem
   }
}
</script>

<style>

</style>
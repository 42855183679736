<template>
    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
        <!--router-link to="to"-->
            <v-card class="mx-auto text-center" elevation="2" id="mcapp" :to="to">
                <v-icon size="40" color="red darken-3" class="pt-6 pb-6" >
                   {{ icon }}
                </v-icon>
            </v-card>
            <div class="pa-2 pt-3">
                <h1 class="text-center font-weight-light body-2 black--text">{{ title }}</h1>
            </div>
        <!--/router-link-->
    </v-col>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: '#'
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
import idb from '@/store/idb';

const state = {
  udata: {
    acezcode: null,
    address: null,
    landmark: null,
  }  
}

const mutations = {
  SET_UDATA (state, data) {
    state.udata = data
  },
}

const actions = {
  saveUdata ({ commit }, data) {
      idb.saveUData(data).then(() => {
        commit('SET_UDATA', data)
      })
  },
  getUdata ({ commit }) {
    idb.getUData().then((data) => {
      commit('SET_UDATA', data)
    })
  },
  clearUData ({ commit }) {
    idb.clearUData().then(() => {
      let tmpdata = {
        status: null,
        docid: null,
        compname: null,
        address: null,
        fname: null,
        mname: null,
        lname: null,
        email: null,
        password: null,
        mobileno: null,
        type: null,
        userid: null
      }  
       commit('SET_UDATA', tmpdata)
    })
  },

}

const getters = {
  udata (state) {
    return state.udata
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
